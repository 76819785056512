
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // backend: 'https://dakonet.ethosh.net/lms/api/',
  // backend: 'http://localhost:8080/api/',
  // backend: 'https://dakonet.ethosh.net/lmsqa/api/',
  backend: 'https://www.dakonet.agilent.com/lms/api/', /** production end point link */
  // backend:'https://www.stgdakonet.agilent.com/lms/api/',
  batchId: 3001,
  batchName: 'Agilent batch',
  // CAPTCH_SECREAT_KEY: '6Ldh4bIaAAAAAOoy_mz-MXLb1EGjYzHF2Hi06Txv', /** development server https://dakonet.ethosh.net captch key  */
  // CAPTCH_SITE_KEY:'6Ldh4bIaAAAAAMkYhhZzLCu4gN9wuHP3ruVyMjNb', /** development server https://dakonet.ethosh.net captch key  */
  // CAPTCH_SECREAT_KEY: '6LdjHGEfAAAAADzFifBd4sUlEthTg2GfWc4-zdmK' /*production captcha key */,
  // CAPTCH_SITE_KEY:'6LdjHGEfAAAAADLHpLsppEVS1cnnoGNqI2fQFNad' /*production captcha key */,
  // CAPTCH_SECREAT_KEY: '6LcL_ZMqAAAAAJb3c8Nq8uZe0QiQ_lK4vsDV8O9Q', /** staging server https://www.stgdakonet.agilent.com/ captch key  */
  // CAPTCH_SITE_KEY:'6LcL_ZMqAAAAAPb_geKGxrFqXfPfJK055uR8r-Xi', /** staging server https://www.stgdakonet.agilent.com/ captch key  */
  CAPTCH_SECREAT_KEY: '6LdlncgqAAAAADnetG0uXdbJ_AXlU6l5UCX-rJKq' /* new production captcha key */,
  CAPTCH_SITE_KEY:'6LdlncgqAAAAABBMezAFCf3wK8XI-uKdbXEZX8RR' /*new production captcha key */,
  DAKONET_SPAIN_EMAIL:'dakonet.spain@agilent.com',
  POLICY_LINK:"https://www.agilent.com/home/privacy-policy"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
